import MuiExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary';
import {withStyles} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {CheckCircle, Delete, RemoveCircle} from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {countBy, findIndex} from 'lodash';
import get from 'lodash/get';
import React, {useState, useEffect} from 'react';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import {INCOMPLETE_STATUS, PARTIAL_STATUS, COMPLETE_STATUS} from './AnswerDetailEdit';
import AnswerQueryPanel from './AnswerQueryPanel';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import gql from 'graphql-tag';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import {lighten} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
   largeLeftSpace: {
      marginLeft: theme.spacing(2),
   },
   leftSpace: {
      marginLeft: theme.spacing(1),
   },
   equipmentSummaryStyle: {
      paddingLeft: theme.spacing(5),
   },
   deleteColorStyle: {
      color: theme.palette.error.main,
      '&:hover': {
         backgroundColor: lighten(theme.palette.error.light, 0.8),
      }  
   }
}));

const ExpansionPanelSummary = withStyles(theme => ({
   root: {
      backgroundColor: theme.palette.type === 'dark' ? '#B5B5B5' : '#fafafa',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 40,
      '&$expanded': {
         minHeight: 40,
      },
   },
   content: {
      margin: '1px 0 !important',
      '&$expanded': {
         margin: '1px 0 !important',
      },
   },
   expanded: {},
}))(MuiExpansionPanelSummary);

const ExpansionPanel = withStyles({
   root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
         borderBottom: 0,
      },
      '&:before': {
         display: 'none',
      },
      '&$expanded': {
         margin: 'auto',
      },
   },
   expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelDetails = withStyles(theme => ({
   root: {
      padding: theme.spacing(0),
   },
}))(MuiExpansionPanelDetails);

const EQUIPMENT_DELETE = gql`
  mutation report_EquipmentDelete($equipmentId: Int!, $reportId: Int!) {
    report_EquipmentDelete(equipmentId: $equipmentId, reportId: $reportId)
  }
`;

/**
 * Component to display equipment expansion panel.
 *
 * Reviewed: 11/07/19
 */
export default function EquipmentInspection({report, area, onStatus, validate, isEditable, refetch}) {
   const [expanded, setExpanded] = useState();
   const [isValidate, setIsValidate] = useState();
   const classes = useStyles();

   const [deleteEquipment] = useMutationFHG(EQUIPMENT_DELETE)

   const handleDeleteEquipment = async (equipmentId) => {
      try {
         await deleteEquipment({
            variables: {
               equipmentId,
               reportId: report?.id
            }
         })
         refetch()
      } catch (error) {
         
      }
   }

   /**
    * Update the status when area and report change. Usually this will only execute on mount.
    */
   useEffect(() => {
      if (area.__completeStatus === PARTIAL_STATUS) {
         const index = findIndex(get(area, 'equipment', []), {__completeStatus: PARTIAL_STATUS});
         if (index >= 0 && index !== expanded) {
            handleExpand(index)(null, true);
         }
      }
      // eslint-disable-next-line
   }, [area]);

   /**
    * Handle expanding the panel.
    * @param index The index of the panel or -1 for the new question panel.
    * @return {Function} The callback for the expanding.
    */
   const handleExpand = index => (event, newExpanded) => {
      const status = get(area, `equipment[${index}].__completeStatus`);

      if (!isEditable || (!area.__isInProgress || (status === PARTIAL_STATUS && expanded !== index))) {
         setExpanded(newExpanded ? index : false);
      } else {
         setIsValidate(true);
      }
   };

   // After render of the validation text, reset for the next time.
   useEffect(() => {
      if (isValidate) {
         setIsValidate(false);
      }
   }, [isValidate]);

   // When the area validate changes, update the equipment validate.
   useEffect(() => {
      if (validate) {
         setIsValidate(validate);
      }
   }, [validate]);

   /**
    * Handle the status change.
    * @param index The index of the equipment.
    * @param equipment The equipment changing status.
    * @return {Function}
    */
   const handleStatus = (index, equipment) => (status, isInProgress) => {
      const equipmentArray = get(area, 'equipment', []);
      const count = get(area, 'equipment.length', 0);
      let newStatus;

      if (equipment.__completeStatus !== status) {
         equipment.__completeStatus = status;
         equipment.__isInProgress = isInProgress;
         if (status === PARTIAL_STATUS && expanded !== index) {
            handleExpand(index)(null, true);
         }
         const {complete = 0, partial = 0} = countBy(equipmentArray, '__completeStatus');
         if (complete === count) {
            if (area.__completeStatus !== COMPLETE_STATUS) {
               newStatus = COMPLETE_STATUS;
            }
         } else if (partial > 0 || complete > 0) {
            if (area.__completeStatus !== PARTIAL_STATUS) {
               newStatus = PARTIAL_STATUS;
            }
         } else if (area.__completeStatus !== INCOMPLETE_STATUS) {
            newStatus = INCOMPLETE_STATUS;
         }

         if (newStatus) {
            onStatus(newStatus, isInProgress);
         }
      }
   };

   let counts = {};
   return (
      <Grid item fullWidth name={'test width'}>
         {get(area, 'equipment', []).map((equipment, index) => {
            counts[equipment.name] = counts[equipment.name] === undefined ? 1 : counts[equipment.name] + 1;
            return (
               <ExpansionPanel
                  key={`expansionPanelEquipment ${equipment.id}`}
                  expanded={expanded === index}
                  onChange={handleExpand(index)}
               >
                  <ExpansionPanelSummary
                     expandIcon={<ExpandMoreIcon/>}
                     aria-controls='panel1a-content'
                     id='panel1a-header'
                     className={classes.equipmentSummaryStyle}
                  >
                     <Grid container direction={'row'} wrap={'nowrap'} justify={'space-between'}>
                        <Grid item container alignItems={'center'} spacing={1}>
                           <Grid item>
                              {equipment.__completeStatus === COMPLETE_STATUS ?
                                 <CheckCircle style={{color: 'green'}}/> :
                                 equipment.__completeStatus === PARTIAL_STATUS &&
                                 <RemoveCircle style={{color: '#ffc107'}}/>}
                           </Grid>
                           <Grid item>
                              <Typography>
                                 {`${equipment.name} - ${counts[equipment.name]}`}
                              </Typography>
                           </Grid>
                        </Grid>
                        {
                           isEditable &&
                           <Grid item>
                              <ConfirmButton
                                 onConfirm={() => handleDeleteEquipment(equipment.id)}
                                 titleKey="delete.equipment.title"
                                 messageKey="delete.equipment.message"
                                 buttonLabelKey={'delete.button'}
                                 confirmProps={{
                                    submitColorStyle: classes.deleteColorStyle
                                 }}
                                 component={Delete}
                                 double
                                 buttonLabelKey2="user.confirmPassword.label"
                                 messageKey2="area.delete.all.question.confirmation2"
                              />
                           </Grid>
                        }
                     </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                     {expanded === index && (
                        <AnswerQueryPanel report={report} equipment={equipment} validate={isValidate}
                                          questionAliases={equipment.questionAliases}
                                          refetch={refetch}
                                          onStatus={handleStatus(index, equipment)} isEditable={isEditable}/>
                     )}
                  </ExpansionPanelDetails>
               </ExpansionPanel>
            )
         })}
      </Grid>
   );
}
