import IconButton from '@material-ui/core/IconButton/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CheckCircle, RemoveCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import gql from 'graphql-tag';
import { get } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useDeleteMutationFHG from '../../fhg/components/data/useDeleteMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import { TEMPLATE_QUERY } from '../builder/InspectionBuilder';
import { QUESTION_ALIAS_FRAGMENT } from '../Types';
import AnswerDetailEdit, {
  COMPLETE_STATUS,
  PARTIAL_STATUS
} from './AnswerDetailEdit';
import {
  ExpansionPanelSummary,
  ExpansionPanel,
  ExpansionPanelDetails
} from './InspectionPanel';
import { PROPERTY_BY_ID_QUERY } from './InspectionView';

const useStyles = makeStyles(theme => ({
  contentStyle: {
    overflow: 'hidden'
  },
  headingStyle: {
    width: '100%',
    height: '100%',
    fontWeight: 500,
  },
  spinnerStyle: {
    color: 'white',
    marginLeft: theme.spacing(0.5),
  },
  expansionStyle: {
    backgroundColor: 'white !important',
    width: '100%',
  },
  infoButtonStyle: {
    alignSelf: 'center',
  },
  deleteButtonStyle: {
    minWidth: 52,
  },
  spaceLeftStyle: {
    marginLeft: 8,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  noVerticalSpace: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  answerSummaryStyle: {
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
    backgroundColor:
      theme.palette.type === 'dark' ? '#B5B5B5' : 'white !important',
  },
}));

export const ANSWER_FRAGMENT = gql`
  fragment answerInfoForPanel on Answer {
    id
    isDeleted
    note
    selectedRegulations
    priorityId
    questionAliasId
    questionAlias {
      id
      isDeleted
      question {
        id
        isDeleted
        text
        questionTypeId
        masterEquipmentId
        entrySetValue
        regulationList {
          id
          reference
          name
          regulation
          standard: regulationStandard {
            id
            name
          }
        }
      }
    }
    statusId
    text
    imageData {
      imageS3
      imageAnnotations
    }
    createdDateTime
    updatedDateTime
  }
`;

const ANSWER_QUERY = gql`
  query AnswerQuery($reportId: [Int], $questionAliasId: [Int]) {
    answer: answer_AllWhere(
      answerSearch: {reportId: $reportId, questionAliasId: $questionAliasId, isDeleted: false}
    ) {
      ...answerInfoForPanel
    }
    priorities: priority_All {
      id
      isDeleted
      name
      value: id
      label: name
      description
    }
  }
  ${ANSWER_FRAGMENT}
`;

export const QUESTION_MARK_REMOVE = gql`
  mutation QuestionMarkRemove($answerId: Int!, $questionAliasId: Int!, $markedForAdd: Boolean, $markedForRemove: Boolean
  ) {
    answer_Delete(answerId: $answerId)
    questionAlias: questionAlias_Update(questionAliasId: $questionAliasId, questionAlias: {markedForAdd: $markedForAdd,
        markedForRemove: $markedForRemove
      }
    ) {
      ...questionAliasInfo
    }
  }
  ${QUESTION_ALIAS_FRAGMENT}
`;

/**
 * Component to edit answer details. The component is an expansion panel.
 *
 * Reviewed: 11/7/19
 */
export default function AnswerPanel({
  report,
  questionAlias,
  validateAnswer,
  expanded,
  onExpand,
  isEditable,
  onDelete,
}) {
  const { mode, id } = useParams();

  const [answer, setAnswer] = useState();
  const { loading, data, statusComponent } = useQueryFHG(ANSWER_QUERY, {
    variables: {
      reportId: report && report.id,
      questionAliasId: questionAlias && questionAlias.id
    },
    skip: !questionAlias || !report,
    fetchPolicy: 'cache-and-network'
  });
  const [
    questionMarkRemove,
    questionMarkRemoveStatusComponent,
    questionMarkRemoveConfirmComponent
  ] = useDeleteMutationFHG(QUESTION_MARK_REMOVE, undefined, {
    titleKey: 'answer.delete.title',
    confirmKey: 'answer.deleteAnswer.confirmation'
  });
  const classes = useStyles();

  // Set the answer from the query data.
  useEffect(() => {
    if (!!data) {
      setAnswer(get(data, 'answer[0]'));
    }
    // eslint-disable-next-line
  }, [data]);

  /**
   * Get refetch the queries after changes.
   *
   * @return {{variables: {reportId: *}, query: *}[]} The query to run after mutations.
   */
  const getRefetchQueries = () => {
    return [
      {query: PROPERTY_BY_ID_QUERY, variables: { id: Number(id), reportId: report.id }, fetchPolicy: 'cache-and-network'},
      {query: TEMPLATE_QUERY, variables: { propertyId: Number(id) }, fetchPolicy: 'cache-and-network'},
    ];
  };

  /**
   * Handle the delete of the answer.
   * @param event The event triggering the delete.
   */
  const handleDelete =  async (event) => {
    await  onDelete(questionAlias?.id)
  };

  if (loading) {
    return statusComponent;
  }

  if (!answer) {
    return null;
  }
  return (
    <ExpansionPanel
      key={`expansionPanel ${questionAlias.id}`}
      expanded={expanded}
      onChange={onExpand}
      className={classes.expansionStyle}
    >
      <ExpansionPanelSummary
        key={`expansionPanelSummary ${questionAlias.id}`}
        expandIcon={<ExpandMoreIcon color='primary' />}
        aria-controls='panel1bh-content'
        id='panel1bh-header'
        className={classes.answerSummaryStyle}
      >
        {questionMarkRemoveConfirmComponent}
        {statusComponent || questionMarkRemoveStatusComponent}
        <Grid
          item
          container
          direction='row'
          alignItems={'center'}
          wrap={'nowrap'}
          justify={'space-between'}
          spacing={1}
        >
          <Grid
            item
            container
            fullWidth={false}
            direction={'row'}
            alignItems={'center'}
            wrap={'nowrap'}
          >
            <Grid item>
              {questionAlias.__completeStatus === COMPLETE_STATUS ? (
                <CheckCircle style={{ color: 'green' }} />
              ) : (
                questionAlias.__completeStatus === PARTIAL_STATUS && (
                  <RemoveCircle style={{ color: '#ffc107' }} />
                )
              )}
            </Grid>
            <Grid
              item
              resizable={true}
              style={{ maxWidth: 'max-content' }}
              name={expanded && `expandedPanel`}
            >
              <Typography
                variant={'subtitle1'}
                className={classes.headingStyle}
              >
                {get(questionAlias, 'question.text')}
              </Typography>
            </Grid>
          </Grid>
          {mode !== 'powerwash' && (
            <Grid item className={classes.deleteButtonStyle}>
              <IconButton
                className={'no-print'}
                aria-label='Delete Question'
                onClick={handleDelete}
                disabled={!isEditable}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <AnswerDetailEdit
          key={`questionDetailEdit${questionAlias.id}`}
          answer={answer}
          validate={validateAnswer}
          isEditable={isEditable}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
