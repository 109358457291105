import Button from '@material-ui/core/Button';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import {CheckCircle, Delete, RemoveCircle} from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import gql from 'graphql-tag';
import {get, find} from 'lodash';
import PropTypes from 'prop-types';
import React, {useState, useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {HIDE_EXPAND_MESSAGE} from '../../Constants';
import DisplayError from '../../fhg/components/DisplayError';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import {COMPLETE_STATUS, PARTIAL_STATUS, DELETED_STATUS} from './AnswerDetailEdit';
import EquipmentInspection from './EquipmentInspection';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import {lighten} from '@material-ui/core/styles';

const AREA_DELETE = gql`
  mutation report_AreaDelete($areaId: Int!, $reportId: Int!) {
    report_AreaDelete(areaId: $areaId, reportId: $reportId)
  }
`;

const useStyles = makeStyles(theme => ({
   contentStyle: {},
   root: {
      width: '100%',
   },
   newAreaStyle: {
      position: 'sticky',
      bottom: -theme.spacing(1),
   },
   deleteColorStyle: {
      color: theme.palette.error.main,
      '&:hover': {
         backgroundColor: lighten(theme.palette.error.light, 0.8),
      }  
   }
}));

export const ExpansionPanel = withStyles({
   root: {
      backgroundColor: 'transparent',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      borderTop: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
         borderBottom: 0,
      },
      '&:before': {
         display: 'none',
      },
      '&$expanded': {
         margin: 'auto',
      },
      width: '100%',
   },
   expanded: {},
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles(theme => ({
   root: {
      // backgroundColor: theme.palette.type === 'dark' ? '#B5B5B5' : '#D2D2D2',
      backgroundColor: theme.palette.type === 'dark' ? '#9B9B9B' : '#D2D2D2',
      // backgroundColor: '#D2D2D2',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 40,
      '&$expanded': {
         minHeight: 40,
      },
   },
   content: {
      margin: '0px 0',
      '&$expanded': {
         margin: '0px 0',
      },
   },
   expanded: {},
}))(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles(theme => ({
   root: {
      backgroundColor: theme.palette.type === 'dark' ? '#A8A8A8' : '#D2D2D2',
      padding: theme.spacing(0, 0, 0),
   },
}))(MuiExpansionPanelDetails);

/**
 * Component to display an Inspection.
 */
export default function InspectionPanel({property, report, areas, overallStatus, isInProgress, isEditable, refetch}) {
   const [expanded, setExpanded] = useState();
   const [isValidate, setIsValidate] = useState();
   const [showExpandMessage, setShowExpandMessage] = useState(false);
   const classes = useStyles();

   const [deleteArea] = useMutationFHG(AREA_DELETE)

   const partialAreas = useMemo(() => find(areas, {__completeStatus: PARTIAL_STATUS}), [areas]);

   const handleDeleteArea = async (areaId) => {
      try {
         await deleteArea({
            variables: {
               areaId,
               reportId: report?.id
            }
         })
         refetch()
      } catch (error) {
         
      }
   }

   useEffect(() => {
      if (!!report && !!areas && areas.length > 0) {
         const isDeleted = get(report, 'isDeleted');

         if (!isDeleted) {
            if (overallStatus === PARTIAL_STATUS) {
               const area = find(areas, {__isInProgress: true}) || partialAreas;
               if (area && area.id !== expanded && (area.__isInProgress || (!expanded))) {
                  handleExpand(area)(null, true);
               }
            }
         }
      }
      // eslint-disable-next-line
   }, [areas, report, overallStatus]);

   // After render of the validation text, reset for the next time.
   useEffect(() => {
      if (isValidate) {
         setIsValidate(false);
      }
   }, [isValidate]);

   /**
    * Handle expanding the panel.
    * @param area The area to be expanded.
    * @return {Function} The callback for the expanding.
    */
   const handleExpand = area => (event, newExpanded) => {
      if (!isInProgress || (area.__completeStatus === PARTIAL_STATUS && expanded !== area.id)) {
         if (partialAreas && area.__completeStatus !== PARTIAL_STATUS) {
            setShowExpandMessage(partialAreas.name);
         }
         setExpanded(newExpanded ? area.id : false);
         setIsValidate(false);
      } else {
         setIsValidate(true);
      }
   };

   if (overallStatus === DELETED_STATUS) {
      return (
         <Grid container direction={'row'} wrap='nowrap' spacing={1}>
            <Grid item>
               <Typography id='inspect.deleted.title' variant={'h6'} color={'inherit'}/>
            </Grid>
            <Grid item>
               <Button component={Link} to={`/inspect/${property.id}`} color='secondary'>
                  <Typography id='inspect.deleted.message'/>
               </Button>
            </Grid>
         </Grid>
      );
   }

   let counts = {};
   return (
      <Grid container direction={'column'} wrap='nowrap' className={`${classes.root} ${classes.fullHeight}`} spacing={2}
            fullWidth>
            <DisplayError error={showExpandMessage && {}} errorId={'inspect.expandIncomplete.message'}
                          values={{name: showExpandMessage}} enableRefresh={false}
                          onClose={() => setShowExpandMessage(false)} autoHideDuration={HIDE_EXPAND_MESSAGE}
            />
         <Grid item container direction={'column'} className={classes.fullHeight} wrap={'nowrap'} overflow='auto'
               fullWidth>

            {areas.map((area) => {
               if (!area.description) {
                  counts[area.name] = counts[area.name] === undefined ? 1 : counts[area.name] + 1;
               }
               return (
                  <ExpansionPanel key={`expansionPanelEquipment ${area.id}`} expanded={expanded === area.id}
                                  onChange={handleExpand(area)}
                  >
                     <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                     >
                        <Grid container direction={'row'} wrap={'nowrap'} justify={'space-between'}>
                           <Grid item container direction={'row'} alignItems={'center'} spacing={1}>
                              <Grid item>
                                 {area.__completeStatus === COMPLETE_STATUS ? <CheckCircle style={{color: 'green'}}/> :
                                    area.__completeStatus === PARTIAL_STATUS &&
                                    <RemoveCircle style={{color: '#ffc107'}}/>}
                              </Grid>
                              <Grid item>
                                 <Typography variant={'subtitle1'} className={classes.headingStyle}>
                                    {area.description || (counts[area.name] > 1 ? `${area.name} - ${counts[area.name]}` : area.name)}
                                 </Typography>
                              </Grid>
                           </Grid>
                           {
                              isEditable &&
                              <Grid item>
                                 <ConfirmButton 
                                    double
                                    onConfirm={() => handleDeleteArea(area.id)}
                                    titleKey="delete.area.title"
                                    messageKey="delete.area.message"
                                    buttonLabelKey={'delete.button'}
                                    confirmProps={{
                                       submitColorStyle: classes.deleteColorStyle
                                    }}
                                    component={Delete}
                                    buttonLabelKey2="user.confirmPassword.label"
                                    messageKey2="area.delete.all.question.confirmation2"
                                 />
                              </Grid>
                           }
                        </Grid>
                     </ExpansionPanelSummary>
                     <ExpansionPanelDetails>
                        {expanded === area.id && (
                           <EquipmentInspection
                              key={`equipmentPanelDetails ${area.id}`}
                              report={report}
                              area={area}
                              validate={isValidate}
                              isEditable={isEditable}
                              refetch={refetch}
                           />
                        )}
                     </ExpansionPanelDetails>
                  </ExpansionPanel>
               )
            })}
         </Grid>
      </Grid>
   );
}

InspectionPanel.propTypes = {
   property: PropTypes.object,               // The property to inspect.
   areas: PropTypes.array,                   // All areas in the master template.
   onDelete: PropTypes.func,                 //Called when a Inspection is deleted.
};

InspectionPanel.defaults = {
   areas: [],
};
