import {countBy, isFunction, delay, findIndex} from 'lodash';
import get from 'lodash/get';
import React, {useState, useEffect} from 'react';
import Grid from '../../fhg/components/Grid';
import {COMPLETE_STATUS, PARTIAL_STATUS, INCOMPLETE_STATUS} from './AnswerDetailEdit';
import AnswerPanel from './AnswerPanel';
import gql from 'graphql-tag';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';

const QUESTION_DELETE = gql`
  mutation report_QuestionDelete($questionAliasId: Int!, $reportId: Int!) {
    report_QuestionDelete(questionAliasId: $questionAliasId, reportId: $reportId)
  }
`;

/**
 * Component to edit question details. The component is an expansion panel.
 *
 * Reviewed: 11/07/19
 */
export default function AnswerQueryPanel({report, equipment, questionAliases, validate, isEditable, refetch}) {
   const [expanded, setExpanded] = useState();
   const [validateAnswer, setValidateAnswer] = useState();

   const [deleteQuestion] = useMutationFHG(QUESTION_DELETE)

   const handleDeleteQuestion = async (questionAliasId) => {
      try {
         await deleteQuestion({
            variables: {
               questionAliasId,
               reportId: report?.id
            }
         })
         refetch()
      } catch (error) {
         
      }
   }

   /**
    * Update the status when questionAliases and equipment change, usually when the component starts.
    */
   useEffect(() => {
      if (equipment.__completeStatus === PARTIAL_STATUS) {
         const index = findIndex(questionAliases, {__completeStatus: PARTIAL_STATUS});
         if (index >= 0 && index !== expanded) {
            handleExpand(index)(null, true);
         }
      }
      // eslint-disable-next-line
   }, []);

   /**
    * Handle expanding the panel.
    * @param index The index of the question of the panel.
    * @return {Function} The callback for the expanding.
    */
   const handleExpand = index => (event, newExpanded) => {
      if (!isEditable || (expanded === undefined || get(questionAliases, `[${expanded}].__completeStatus`) !== PARTIAL_STATUS)) {
         setExpanded(newExpanded && index);
      } else if (expanded) {
         setValidateAnswer(expanded)
      }
   };

   return (
      <Grid item fullWidth>
         {questionAliases && questionAliases.map((questionAlias, index) => (
            <AnswerPanel report={report} questionAlias={questionAlias}
                         validateAnswer={validateAnswer || validate}
                         expanded={expanded === index} onExpand={handleExpand(index)}
                         isEditable={isEditable}
                         onDelete={handleDeleteQuestion}
            />
         ))}
      </Grid>
   );
}
